import withI18NextSSR from 'i18n/i18next-ssr.hoc';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { compose } from 'redux';
import Login from 'components/auth/login';
import { Auth } from 'components/auth/shared/auth';
import PageHead from 'components/head/head';
import { LinkText } from 'components/i18n/link-text';
import app from 'lib/app';
import { getCountryCodeByAppLanguage } from 'lib/app/selectors';
import { SELF_SIGNUP_COUNTRIES, getAuthInitialProps } from 'lib/auth/utils/signup';
import { UrlHelper } from 'lib/url-helper';
import { useAppSelector } from 'lib/utils';

const LoginPage = () => {
  const { t } = useTranslation('loggedOut');

  const countryCode = useAppSelector(getCountryCodeByAppLanguage);
  const isSignupAllowed = SELF_SIGNUP_COUNTRIES.includes(countryCode);
  const isLoading = useAppSelector((state) => state.auth.form.disabled);

  useEffect(() => {
    if (window.HubSpotConversations) {
      const status = window.HubSpotConversations.widget.status();
      if (status.loaded) {
        window.HubSpotConversations.widget.remove();
      }
    }
  }, []);

  return (
    <Auth.Wrapper>
      <PageHead title={`${t('supplierLogin')} | ${t('bridebookBusiness')}`} plainTitle />
      <Auth.Content>
        <Auth.LoadingOverlay show={isLoading} />
        <Login />
      </Auth.Content>

      {isSignupAllowed && (
        <Auth.Footer>
          <Trans ns="loggedOut" i18nKey="dontHaveAccountYet">
            Don't have a business account?{' '}
            <LinkText
              href={UrlHelper.auth.signUp}
              passHref
              color="white"
              bold={false}
              style={{ textDecoration: 'underline' }}>
              Sign up for free
            </LinkText>
          </Trans>
        </Auth.Footer>
      )}
    </Auth.Wrapper>
  );
};

LoginPage.getInitialProps = getAuthInitialProps;

export default compose(app, withI18NextSSR(['loggedOut']))(LoginPage);
